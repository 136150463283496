
import {
  FilterKey,
  IVacancy,
  IWorkplace,
  LanguageLocale,
  LocalizationData,
  LocalizationPath,
  placeholderImageUrl
} from '@tokyojob/core'
import {
  ButtonFactory,
  CButton, CFilterTagList, CIconFactory,
  CLoader, FilterGroupFactory, FilterQueryManager, FilterTagFactory, getLocalization, getVacancyTreeFilterData, IButton, IconPlacement,
  IconSize,
  IconType, IFilterGroup, IFilterTag, ISelectedFilterMultiOptions, IVacancyFilterTreeData
} from '@tokyojob/frontend-components'
import {
  getLocalizationStore,
  getWorkplaceStore,
  IPaginateProps,
  LocalizationService,
  QueryStringEntityFactory,
  VacancyListMetaFactory
} from '@tokyojob/frontend-core'
import Vue from 'vue'
import { Layout } from '~/base/utils/constants/layout'
import authenticatedOptional from '~/middleware/authenticated-optional'
import CFilterSideMenu from '~/modules/app/components/c-filter/c-filter-side-menu/c-filter-side-menu.component.vue'
import { ISelectedVacancyFilters } from '~/modules/app/components/c-filter/interface/i-selected-vacancy-filters'
import { SelectedVacancyFiltersDomain } from '~/modules/app/components/c-filter/util/selected-vacancy-filters-domain'
import { IApp } from '~/types/nuxt'
import CFilter from '../modules/app/components/c-filter/c-filter.component.vue'
import LandingPageBannerMobile from '../modules/app/components/elements/landing-page-banner-mobile.vue'
import LandingPageBannerWeb from '../modules/app/components/elements/landing-page-banner-web.vue'
import RestaurantAdBannerMobile from '../modules/app/components/elements/restaurant-ad-banner-mobile.component.vue'
import RestaurantAdBannerWeb from '../modules/app/components/elements/restaurant-ad-banner-web.component.vue'
import CVacancyGrid from '../modules/auth/components/candidate/c-vacancy-grid/c-vacancy-grid.component.vue'
import PaginateUsecase from '../modules/vacancy/usecases/paginate.usecase'

const filterQueryManager = new FilterQueryManager()
const selectedVacancyFiltersDomain = new SelectedVacancyFiltersDomain(filterQueryManager, new FilterTagFactory(), {})

interface IData {
  /**
   * Language passed to confirmation modal.
   */
  language: LanguageLocale
  /**
   * Object containing results for each of the filters
   */
  selectedFiltersData: ISelectedVacancyFilters
  /**
   * Array of IFilter objects
   */
  filters: IFilterGroup
  /**
   * The boolean that determines whether to show the list or the map
   */
  isShowingMap: boolean
  /**
   * The currently published vacancies.
   */
  vacancies: IPaginateProps<IVacancy>
  /**
   * The currently available workplaces
   */
  workplaces?: IWorkplace[]
  /**
   * Link to the map page
   */
  mapBtn: IButton
  /**
   * Object containing hierarchical data for the c-filter
   */
  vacancyFilterTreeData: IVacancyFilterTreeData
  /**
   * The filter tags to display in the UI, below the filters component.
   * These should react to changes in the querystring, and fire close events to trigger a filter's data reset.
   */
  filterTags: IFilterTag[]
  /**
   * Whether the filter side menu is open or not
   * Only used on screen sizes below 768px
   */
  filterSideMenuOpen: boolean
  /**
   * Button to open filter side menu when on screen width below 768px
   */
  filterSideMenuBtn: IButton
  /**
   * Dictionary of which filter popouts components are open
   */
  popoutdictionary: Record<string, boolean>
  /**
   * Currently applied queries in the vacancy list in string format
   */
  strQuery: string
  /**
   * True, if the mounted initialization is already done
   */
  hasMountedInitialized: boolean
  /**
   * Display the loading overlay
   */
  isLoading: boolean
  /**
   * localization info from the context
   */
  localization: LocalizationData

  App: IApp
}
export default Vue.extend({
  layout: Layout.Home,
  middleware: [authenticatedOptional],
  /**
   * This must be in the page and not in the layout
   */
  components: {
    CFilter,
    CVacancyGrid,
    CButton,
    LandingPageBannerMobile,
    LandingPageBannerWeb,
    CFilterTagList,
    CFilterSideMenu,
    CLoader,
    RestaurantAdBannerWeb,
    RestaurantAdBannerMobile
  },
  /**
   * TODO - This needs to be refactored to move anything we can out of asyncData
   */
  async asyncData({ App, route, redirect, req }) {
    const localizationStore = getLocalizationStore()
    const language = App.localizationSelector.language
    const localization = localizationStore.get(language)
    if (!localization) throw new Error('Localization data is missing')

    const locale = App.localizationSelector.languageLocale
    // We fetch the vacancy filter information
    const vacancyFilterTreeData: IVacancyFilterTreeData = getVacancyTreeFilterData().all()

    // Set the vacancy filter information to selectedVacancyFiltersDomain
    selectedVacancyFiltersDomain.setTreeData(vacancyFilterTreeData)

    // We filter out the invalid information in the query string and redirect the user
    const result = selectedVacancyFiltersDomain.filterInvalidQuery(App, route, process.server, '/', redirect)
    if (result.hasRemovedAQuery) return
    const filters = FilterGroupFactory.VacancySearchFilterGroup(localization, vacancyFilterTreeData)
    const popoutdictionary = {} as Record<string, boolean>
    for (const filter in filters.filters) {
      const name = filters.filters[filter].name
      popoutdictionary[name] = false
    }
    const paginatedUseCase = new PaginateUsecase(App)
    const selectedFiltersData = selectedVacancyFiltersDomain.fromQuery(result.query)
    const filterTags = selectedVacancyFiltersDomain.createFilterTags(localization, selectedFiltersData)
    const strQuery = JSON.stringify(result.query)
    const page = route.query.page ? (route.query.page as string) : '1'
    const paginatedVacancies = await paginatedUseCase.execute(
      '',
      process.server,
      QueryStringEntityFactory({
        ...result.query,
        page,
        locale
      })
    )
    return {
      language: locale,
      isSearching: false,
      isShowingMap: false,
      vacancies: paginatedVacancies,
      workplaces: [],
      selectedFiltersData,
      visaTypeFilter: [],
      vacancyFilterTreeData,
      filters,
      filterSideMenuOpen: false,
      popoutdictionary,
      strQuery,
      filterTags
    }
  },
  data(): IData {
    const localization: LocalizationData = getLocalization(this.App as IApp)
    const locale = this.App.localizationSelector.languageLocale
    const mapBtn = ButtonFactory.button({
      label: LocalizationService.t<string>(localization, LocalizationPath.map),
      classes: 'background-img row-center map-btn-text color-shades-black align-center br-10 g-10',
      height: '70px',
      icon: CIconFactory.Icon({ icon: 'map-location-dot', placement: IconPlacement.Left }),
      link: { router: this.$router, link: '/job/map' }
    })
    const filterSideMenuBtn = ButtonFactory.button({
      label: LocalizationService.t<string>(localization, LocalizationPath.filterSideMenu),
      icon: CIconFactory.Icon({ icon: 'magnifying-glass', type: IconType.solid, size: IconSize.large }),
      classes: 'row-around align-center pv-5 ph-10 g-5 theme-shades-dark1-color br-10 filter-button-text-size',
      cyTag: 'c-filter-sidemenu-button'
    })
    let query = {}
    if (process.server) query = this.$route.query
    else {
      const formattedFilters = this.App.state.formattedFilters
      if (formattedFilters && formattedFilters != '') query = JSON.parse(formattedFilters)
    }

    const selectedFiltersData = selectedVacancyFiltersDomain.fromQuery(query)
    const filterTags = selectedVacancyFiltersDomain.createFilterTags(localization, selectedFiltersData)

    // Do we event need this???
    const workplaceStore = getWorkplaceStore()

    return {
      popoutdictionary: {},
      mapBtn,
      language: LanguageLocale.USen,
      isShowingMap: false,
      vacancies: { rows: [], meta: {} },
      workplaces: workplaceStore.values(),
      selectedFiltersData,
      vacancyFilterTreeData: {} as IVacancyFilterTreeData,
      filters: {} as IFilterGroup,
      filterTags,
      filterSideMenuOpen: false,
      filterSideMenuBtn,
      strQuery: '',
      hasMountedInitialized: false,
      isLoading: false,
      localization,
      App: this.App
    }
  },
  watch: {
    /**
     * Watches the route for filter changes.
     */
    async $route({ query }) {
      const strQuery = JSON.stringify(query)
      // Prevent triggering a duplicated API call
      if (this.strQuery === strQuery) return
      await this.refreshVacancyList(query)
      this.strQuery = query
    }
  },
  async mounted() {
    filterQueryManager.initialize(this.App, this.$router, this.$route)
    let query = {}
    if (process.server) query = this.$route.query
    else {
      const formattedFilters = this.App.state.formattedFilters
      if (formattedFilters && formattedFilters != '') query = JSON.parse(formattedFilters)
    }
    this.SelectedFiltersData = selectedVacancyFiltersDomain.fromQuery(query)
    this.hasMountedInitialized = true
  },
  computed: {
    SelectedFiltersData: {
      set(value: ISelectedVacancyFilters) {
        this.selectedFiltersData = value
        selectedVacancyFiltersDomain.setTreeData(this.vacancyFilterTreeData)
        this.filterTags = selectedVacancyFiltersDomain.createFilterTags(this.localization, this.SelectedFiltersData)
        /**
         * We only touch the URL query when the mounted initialization is done
         * because Vue will throw us a warning that we attempted to modify the URL query twice in the same frame
         */
        if (this.hasMountedInitialized) {
          const query = selectedVacancyFiltersDomain.toQuery(value)
          filterQueryManager.modifyQuery(query, this.App, this.$router, this.$route)
        }
      },
      get(): ISelectedVacancyFilters {
        return this.selectedFiltersData
      }
    },
    ShowTopBanner(): boolean {
      return !this.SelectedFiltersData.jobCategory
    }
  },
  methods: {
    /**
     * Executes the vacancy search and returns a paginated result.
     */
    async updatedVacancies(query: any): Promise<IPaginateProps<IVacancy>> {
      const app: IApp = this.App
      const locale = app.localizationSelector.languageLocale
      const paginatedUsecase = new PaginateUsecase(app)
      const iVacancies = await paginatedUsecase.execute(
        '',
        process.server,
        QueryStringEntityFactory({
          ...query,
          locale
        })
      )
      return iVacancies
    },
    /**
     * Refreshes the vacancy list in the page
     */
    async refreshVacancyList(query: Dictionary<string | (string | null)[]>): Promise<void> {
      ;(this.$refs as any).loadingOverlay.show()
      this.vacancies = await this.updatedVacancies(query)
      ;(this.$refs as any).loadingOverlay.hide()
    },
    /**
     * Called when an item in the filter selection component was selected select item 5-1034 visa
     */
    handleItemSelected(data: string, category: string): void {
      this.SelectedFiltersData = selectedVacancyFiltersDomain.addDataToISelectedVacancyFilters(
        this.SelectedFiltersData,
        category as FilterKey,
        data
      )
    },
    /**
     * Updates the filter data with the new multi filter values.
     */
    onMultiFilterApplied(newFilter: ISelectedFilterMultiOptions): void {
      // TODO: update this to use the SelectedVacancyFiltersDomain.addDataToISelectedVacancyFilters
      this.SelectedFiltersData = { ...this.SelectedFiltersData, multiOption: newFilter }
    },
    /**
     * Removes a tag from the filter tags collection.
     */
    onTagClose(value: IFilterTag): void {
      this.SelectedFiltersData = selectedVacancyFiltersDomain.removeSelection(
        this.SelectedFiltersData,
        value.filterKey! as FilterKey
      )
    },
    /**
     * Scroll to the top of the vacancies list view when emit received
     * after a page change
     */
    scrollToTop(): void {
      const vacancyTitle = document.getElementById('ribbon')
      const top = vacancyTitle!.offsetTop as number
      window.scrollTo({
        top: top,
        behavior: 'smooth'
      })
    },
    togglePopout(name: string, status: boolean) {
      for (const item in this.popoutdictionary) {
        if (item === name) {
          this.popoutdictionary[item] = status
        } else {
          this.popoutdictionary[item] = false
        }
      }
    },
    /**
     * Open the side menu when on small screen sizes
     */
    openSideMenu(): void {
      this.filterSideMenuOpen = true
      document.getElementById('__nuxt')!.classList.add('overflow-hidden')
    },
    /**
     * Open the dide menu when on small screen sizes
     */
    closeSideMenu(): void {
      this.filterSideMenuOpen = false
      document.getElementById('__nuxt')!.classList.remove('overflow-hidden')
    },
    /**
     * Receive new selected tags from side menu then close side menu
     */
    saveAndCloseSideMenu(selectedFiltersDataSideMenu: ISelectedVacancyFilters) {
      this.SelectedFiltersData = selectedFiltersDataSideMenu
      this.closeSideMenu()
    }
  },
  head() {
    const localization = getLocalization(this.App)
    const title = LocalizationService.t<string>(localization, LocalizationPath.VacancyListPageMetaTagTitle)
    const meta = VacancyListMetaFactory.metaTags(localization, this.App.localizationSelector.languageLocale)
    // preload the map image
    const mapImgLink = 'https://tokyojob-public.s3.ap-northeast-1.amazonaws.com/illustrations/button_bg_map_opt_sm.webp'
    const link = [
      { rel: 'preload', as: 'image', href: placeholderImageUrl },
      { rel: 'preload', as: 'image', href: mapImgLink }
    ]
    return { title, meta, link }
  }
})
